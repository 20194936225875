
import EncryptionAPI from '../communications/interceptors/apiInterceptor'
export const setPasswordMixin = {
  computed: {
    key () {
      return process.env.VUE_APP_ENCRYPTION_KEY
    }
  },
  methods: {
    getHeaders () {
      return {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }
    },
    decryptEmailToken: async function (token) {
      var axiosConfig = this.getHeaders()
      var rtn = ''
      try {
        var json = '{"key":"' + this.key + '","value":"' + token + '","IsEncrypt":false}'
        await EncryptionAPI.post('encryption/token', json, axiosConfig).then((response) => {
          rtn = response.data
        })
      } catch (_) {
        // eslint-disable-next-line
        console.log('Error calling decryptEmailToken')
      }
      return rtn
    }
  }
}
